// @flow
import * as React from 'react';
import { SectionPageTitle, useGetComponent, useSlotData, } from '@haaretz/htz-components';

import type { MainSlotType, } from '../../flowTypes/MainSlotType';

type Props = {
  data: MainSlotType,
  globalLazyload: number,
};

export default function MainSlot({ globalLazyload, }: Props): React.Node {
  const main = useSlotData('main');

  const data = main || [];


  const getComponent = useGetComponent();
  return (
    <>
      <SectionPageTitle />
      {data
        && data.map(element => {
          const identifier = element.kind || element.inputTemplate;
          const customIdentifier = identifier === 'ClickTrackerWrapper'
            ? 'widePageClickTrackerBannersWrapper'
            : null;
          const Component = getComponent(customIdentifier || identifier, {
            ...element,
            loadPriority: element.loadPriority,
            isExpanded: element.isExpanded,
            preventRender: element.preventRender,
            // rootMargin: globalLazyload,
          });

          return identifier && identifier.includes('MiddleRuler') ? (
            <Component
              key={element.contentId}
              notificationType="MiddleRuller"
              buttonText=""
              text1={element.text}
              text2={element.text2}
              buttonUrl={element.actionUrl}
            />
          ) : Component != null ? (
            <Component key={element.contentId} {...element} />
          ) : null;
        })}
    </>
  );
}
