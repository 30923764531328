// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  LayoutRow,
  LayoutContainer,
  BIRequest,
  useGetComponent,
  RainbowDataProvider,
  RainbowSlot,
  useSlotData,
  HeydayConfig,
  useQueryParam,
} from '@haaretz/htz-components';

import HeaderSlot from './HeaderSlot';
import MainSlot from './MainSlot';

type Props = {
  isSectionArticle: boolean,
  globalLazyload: number,
  path?: string,
};

SectionPageSlotsLayout.defaultProps = {
  isSectionArticle: false,
  path: null,
};

function SectionPageSlotsLayout({
  globalLazyload,
  isSectionArticle,
  path,
}: Props): React.Node {
  const { theme, } = useFela();
  const getComponent = useGetComponent();
  const preHeader = useSlotData('preHeader');
  const header = useSlotData('header');
  const postHeader = useSlotData('postHeader');
  const postMain = useSlotData('postMain');
  const footer = useSlotData('footer');
  const [ searchQueryParam, ] = useQueryParam('q');

  const extractElements = slot => slot.map(element => {
    const Element = getComponent(element.inputTemplate, {
      ...element,
      loadPriority: element.loadPriority,
      isExpanded: element.isExpanded,
      preventRender: element.preventRender,
      // rootMargin: globalLazyload,
    });
    const { properties, ...elementWithoutProperties } = element;

    if (element.inputTemplate === 'PageFooter') {
      return (
        <Element
          key={elementWithoutProperties.contentId}
          {...elementWithoutProperties}
          {...properties}
          shouldRenderScripts
        />
      );
    }
    return (
      <Element
        key={elementWithoutProperties.contentId}
        {...elementWithoutProperties}
        {...properties}
      />
    );
  });
  const bgc = isSectionArticle ? 'white' : theme.color('layout', 'rowBg');
  let pageType = 'section';

  let additionalInfo = null;
  let searchPathAndQuery = null;
  if (path && path === HeydayConfig?.recipesSearchResultPage) {
    pageType = 'Search';
    additionalInfo = searchQueryParam ? { searchTerm: searchQueryParam, } : null;
    searchPathAndQuery = `${path}?q=${searchQueryParam}`;
  }

  return (
    <React.Fragment>
      <RainbowDataProvider>
        <RainbowSlot id="top-strip" />
        <BIRequest pageType={pageType} path={searchPathAndQuery || path} additionalInfo={additionalInfo} />
        {preHeader ? <LayoutRow>{extractElements(preHeader)}</LayoutRow> : null}
        {/* Layout row is inside HeaderSlot Component because its miscStyles depend on state */}
        <HeaderSlot bgc={isSectionArticle ? 'white' : [ 'layout', 'rowBg', ]} content={header} isSectionArticle={isSectionArticle} />
        {postHeader ? (
          <LayoutRow bgc={bgc}>
            <LayoutContainer bgc="transparent">{extractElements(postHeader)}</LayoutContainer>
          </LayoutRow>
        ) : null}
        <LayoutRow
          tagName="main"
          id="pageRoot"
          bgc={bgc}
          miscStyles={{
            flexGrow: 1,
            // Only kinneret-level section has isSectionArticle
            ...(isSectionArticle
              ? {}
              : { paddingBottom: [ { until: 's', value: '4rem', }, { from: 's', value: '8rem', }, ], }),
          }}
        >
          <MainSlot globalLazyload={globalLazyload} />
        </LayoutRow>
        {postMain ? (
          <LayoutRow bgc={bgc} miscStyles={{ display: [ { until: 's', value: 'none', }, ], }}>
            <React.Fragment>{extractElements(postMain)}</React.Fragment>
          </LayoutRow>
        ) : null}
        {footer ? <LayoutRow bgc={bgc}>{extractElements(footer)}</LayoutRow> : null}
        <RainbowSlot id="bottom-strip" />
      </RainbowDataProvider>
    </React.Fragment>
  );
}

export default SectionPageSlotsLayout;
